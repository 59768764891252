import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";

type Referrallist = {
    affiliate_below: string;
    affiliate_casino: string;
    affiliate_lotto: string;
    affiliate_slot: string;
    affiliate_sport: string;
    code: string;
    link_refer: string;
    referral_count: number;
    sum_money: string;
};

type UseUserReferralReturn = {
  data: Referrallist | null;
  isLoading: boolean;
  isError: any;
};

export default function useUserReferral(): UseUserReferralReturn {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<UseUserReferralReturn>(
    isLoggedIn ? `/api/v1/users/referral` : null,
    fetcherAuth
  );

  return {
    data: data?.data || null,
    isLoading: !error && !data,
    isError: error,
  };
}
